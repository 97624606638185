@if (offer?.images) {
  <img
    class="object-cover w-full"
    [srcset]="offer?.images.htmlSrcset"
    sizes="(min-width: 1280px) 66vw, 100vw"
    [alt]="offer?.images.alt"
    [src]="offer?.images.smallest.url"
    [ngClass]="{
      'lg-bottom-left-radius lg:rounded-r-none h-full': size === 'medium',
      'top-border-radius': !boxHasBanner,
    }"
    [attr.loading]="lazyLoadImage ? 'lazy' : 'eager'"
    [width]="offer?.images.original.dimensions.width"
    [height]="offer?.images.original.dimensions.height"
  />
}
