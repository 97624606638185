import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Offer } from 'prismic';
import { CurrencyFormatterPipe } from 'utils';

@Component({
  selector: 'lib-offer-box-price',
  templateUrl: './offer-box-price.component.html',
  styleUrls: ['./offer-box-price.component.scss'],
  standalone: true,
  imports: [CurrencyFormatterPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBoxPriceComponent {
  @Input() offer: Offer;
  @Input() priceFontSize: string;
}
