@if (template) {
  <div
    role="tooltip"
    class="fixed z-9999999999 bg-gray-700 text-white shadow-2xl p-5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0 md:left-auto md:bottom-auto md:p-3 rounded-xl w-[calc(100%-2rem)] max-w-full md:max-w-lg lg:max-w-xl"
    [style.left]="position ? position.left + 'px' : ''"
    [style.top]="position ? position.top + 'px' : ''"
    (mouseover)="mouseover()"
    (mouseout)="mouseout()"
  >
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
  <div class="fixed inset-0 z-9999999998" (touchend)="backgroundClick($event)"></div>
}
