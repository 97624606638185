import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Offer } from 'prismic';

@Component({
  selector: 'lib-offer-box-image',
  templateUrl: './offer-box-image.component.html',
  styleUrls: ['./offer-box-image.component.scss'],
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBoxImageComponent {
  @Input() offer: Offer;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() boxHasBanner: boolean;
  @Input() lazyLoadImage = true;
}
